<template>
  <div>
    <h4>Terms & Conditions Digital use DN Media Group Global AS Services</h4>
    <p><strong>These terms are updated 8.23.2023</strong></p>
    <ol>
      <li>
        <strong class="d-block font-weight-normal">GENERAL</strong>
        These general terms apply to any product of DN Media Group Global AS, company registration no. 937 593 821, and
        related publications, IntraFish Media, Upstream, Recharge, Hydrogen Insight and TradeWinds (hereinafter DN Media
        Group Global), such as newspapers and magazines, apps, newsletters and websites linked to DN Media Group Global,
        including the Jobs and Careers sites. Hereinafter together these are called “the Services”. The content is
        published by DN Media Group Global AS. By using the Services you are agreeing to these general terms.
      </li>
      <li>
        <strong class="d-block font-weight-normal">(COPY)RIGHTS</strong>
        DN Media Group Global has rights to all content, including text, images, graphics, logos, sound, videos, and all
        other material published by DN Media Group Global, unless otherwise stated. All rights to DN Media Group Global,
        including other brands, logos, branding elements, trade secrets and intellectual property belong to DN Media
        Group Global, unless otherwise stated. As a user you may only use the Services for personal and non-commercial
        purposes. You may download, link and print content from the services for private and non-commercial purposes,
        provided that you respect our copyright and other terms and conditions. You may not use content for any other
        purpose or in any other way than is clearly stated. <br>
        Any or all forms of media monitoring, copy production, scraping, crawling, indexing, text/datamining,
        reproduction sales or redistribution of all or part of the Content published by DN Media Group Global AS
        requires a valid agreement. Contact DN Media Group Global AS for an agreement on such use of Content.
      </li>
      <li>
        <strong class="d-block font-weight-normal">RESPONSIBILITIES</strong>
        DN Media Group Global would like to inform users that inaccuracies and mistakes may occur in the content. DN
        Media Group Global declines responsibility for this. DN Media Group Global also declines responsibility for any
        loss as a consequence of mistakes and inaccuracies in the content, unavailability, downtime or other technical
        problems, or mistakes in the content that DN Media Group Global is referring to or collecting information from.
        DN Media Group Global declines responsibility for any losses incurred related to the content in accordance with
        current legislation.
      </li>
      <li>
        <strong class="d-block font-weight-normal">ADVERTISEMENTS</strong>
        Advertisers are responsible for the advertisements that appear in the Services. DN Media Group Global AS resumes
        no responsibility for the content of the advertisements.
      </li>
      <li>
        <strong class="d-block font-weight-normal">LINKS</strong>
        Content may include links to information and content on other services. DN Media Group Global AS declines any
        responsibility for content in or via such links.
      </li>
      <li>
        <strong class="d-block font-weight-normal">USER GENERATED CONTENT</strong>
        As a user you may choose to deliver content to DN Media Group Global AS. By sending in material you agree to
        hand over an unconditional, non-exclusive, unpaid, transferable and perpetual right to publish the content via
        the Services, in any format and in any publication relating to DN Media Group Global AS, in any channel or
        platform, both existing and which may be developed in the future. Furthermore, DN Media Group Global AS is
        granted the right to change and edit the material within the framework of current legislation. By sending in
        content to DN Media Group Global AS you confirm that you have the rights to the material in question. If rights
        to any material need to be further clarified, it is your responsibility to clearly notify DN Media Group Global
        AS. Failing to do so makes you as supplier of the content accountable for any financial loss DN Media Group
        Global AS may suffer as a consequence of missing or unclear rights. DN Media Group Global AS will not reimburse
        for any content supplied, unless otherwise clearly agreed in writing prior to delivery.
      </li>
      <li>
        <strong class="d-block font-weight-normal">DISCUSSION FORUMS AND SIMILAR</strong>
        If you engage in discussion forums or similar relating to content or services published in any DN Media Group
        Global including social media, we urge you to behave and write in a courteous manner. DN Media Group Global AS
        reserves the right to at any time remove content that DN Media Group Global AS deems offensive or unsuitable.
        You can be held accountable for your behavior on discussion forums and similar. DN Media Group Global AS
        reserves the right to seek compensation for any losses or damages incurred due to inappropriate behavior on our
        Services and social media platforms. DN Media Group Global AS resumes no responsibility for the content on
        discussion forums and similar and recommends caution when using such content.
      </li>
      <li>
        <strong class="d-block font-weight-normal">PRIVACY</strong>
        DN Media Group Global AS privacy policy applies for any processing of personal data in connection with our
        Services. Our privacy policy can be found <a href="https://privacy.dngroup.com/privacy-policy">here</a>.
      </li>
      <li>
        <strong class="d-block font-weight-normal">FINANCIAL INFORMATION</strong>
        Users of financial information from the Service must accept the following conditions: Stock and other financial
        information from third party suppliers may contain delays, errors, inadequacies and inaccuracies. DN Media Group
        Global AS resumes no responsibility for such content and cannot be held accountable for information, analysis,
        rates, indices, stock prices or numbers supplied by such partners. Any use of financial information, analysis,
        rates, indices, stock prices or numbers to perform or not perform financial and non-financial decisions and
        transactions is at the sole responsibility and risk of the user. The user is made aware of the risk connected to
        such use. DN Media Group Global AS resumes no liability for financial and non- financial transactions that are
        made, or not made, based on information on our Services. The user may not hold DN Media Group Global AS, DN
        Media Group Global or related companies, or suppliers or sources of information to the Service accountable for
        delays, errors or deficiencies in the content on our Services. You may only use the content on our Services for
        personal and non-commercial purposes. Third-party content on our Services is wholly owned by the supplier of the
        content and they have intellectual property rights to their content. It is unlawful to redistribute, sell or
        commercially take advantage of processed or non-processed information on the Services, unless a written valid
        agreement with DN Media Group Global AS that explicitly grants such rights.
      </li>
      <li>
        <strong class="d-block font-weight-normal">CHANGES IN THE GENERAL TERMS AND CONDITIONS</strong>
        DN Media Group Global AS may revise these terms and conditions whenever appropriate, including based on changes
        in relevant legislation. We will duly announce major changes in the terms and conditions, however we recommend
        that you update yourself on the general terms on a regular basis.
      </li>
      <li>
        <strong class="d-block font-weight-normal">DISPUTES</strong>
        These terms are subject to Norwegian law and any dispute that may occur in connection with the content or use of
        content on our Services will be settled in Norwegian courts.
      </li>
      <li>
        <strong class="d-block font-weight-normal">ENQUIRIES</strong>
        Please contact us on <a href="mailto:editorial@rechargenews.com">editorial@rechargenews.com</a> if you have any questions.
      </li>
    </ol>

    <h4 class="mt-5">Terms & Conditions Digital use Markets section</h4>
    <p><strong>These terms are updated 8.23.2023</strong></p>
    <ul>
      <li>
        By continuing to access or use this website, you expressly acknowledge and agree to these terms and conditions
        set out below (“Terms of Use“)
      </li>
      <li>
        DN Media Group Global and our data suppliers may update or change these Terms of Use at any time and without
        prior notice to you, so you should review these terms and conditions whenever accessing or using this website.
      </li>
      <li>
        All information and material displayed on the Markets platform are the property of DN Media Group Global AS
        and/or its affiliates and data partners and is subject to copyright. You are entitled to view, copy and print
        any documents from this website but only for your own personal purposes. You acquire absolutely no rights or
        licenses in or to the website or the materials contained within the website other than the limited right to use
        the website in accordance with these Terms of Use. Any sale, transmission or redistribution of this website or
        its content, and any copying, modification or other use of this website or its content for any purposes other
        than your own personal purposes, are strictly prohibited.
      </li>
      <li>
        You may not copy, reproduce, recompile, decompile, disassemble, reverse engineer, distribute, publish, display,
        perform, modify, upload to, create derivative works from, transmit or in any way exploit all or any part of the
        website, except as expressly provided in this website terms of use. The contents of the website may not be
        recirculated, redistributed, resold or published by you without DN Media Group Global prior written consent and
        the permission of the specific data providers. If you download any information or software from this website,
        you agree that you will not copy it or remove or obscure any copyright or other notices or legends contained in
        any such information. Modification of the website content would be a violation of DN Media Group Global
        copyright and other proprietary rights. You may not use this website for any illegal purpose or in any manner
        inconsistent with applicable law or this website terms of use. You may not offer any part of the website for
        sale or distribute it over any other medium including but not limited to television or radio broadcast, a
        computer network or hyperlink framing on the internet without the prior written consent of DN Media Group
        Global. You may not use any of DN Media Group Global and/or its affiliates trademarks, trade names or service
        marks in a manner that suggests that such names and marks belong to or are associated with you or are used with
        DN Media Group Global consent, and you acknowledge that you have no ownership rights in and to any of these
        names and marks.
      </li>
      <li>
        You may not use the content to develop, support, create or provide: (i) any pricing or (ii) any index.
      </li>
      <li>
        This website is provided solely for information purposes and, with the exception of these Terms of Use or as
        otherwise indicated, is not intended to, and shall not constitute any offer or acceptance with the respect to
        any transactions or other matters. Except for these Terms of Use, this website shall not create any legal
        relationship between you and DN Media Group Global. This website and the information and material which it
        contains are subject to change at any time by DN Media Group Global and our data suppliers without notice and DN
        Media Group Global reserves the right to suspend, terminate or restrict your access to or use of this website.
      </li>
      <li>
        This website is provided “AS IS” without any warranty of any kind, express or implied, including, but not
        limited to, any implied warranty of merchantability, fitness for a particular purpose, accuracy or completeness
        of the information contained on this website or in respect of any materials or products referred to on this
        website, or non-infringement. DN Media Group Global does not warrant that the website is compatible with your
        equipment or that the website is free of errors, viruses or worms. In no event shall DN Media Group Global or
        its data partners be liable for any damage you may suffer as a result of such destructive features. All
        warranties, whether express or implied, are hereby excluded to the extent permitted by law.
      </li>
      <li>
        DN Media Group Global and its directors, officers, employees, agents and partners shall, to the extent permitted
        by law, have no liability, contingent or otherwise, whether caused by the negligence of its employees,
        subcontractors, agents, suppliers, or otherwise, to you or to third parties for the accuracy, timeliness,
        completeness, reliability, performance or continued availability of this website or for delays or omissions
        therein, including, but not limited to, inaccuracies or errors in or omissions from quotes and financial data.
        DN Media Group Global shall have no responsibility to maintain the information or services made available on
        this website or to supply any corrections or updates in connection with such information or services. In no
        event will DN Media Group Global, its suppliers, or its third party agents be liable to you for any direct,
        special, indirect, incidental, punitive or consequential damages (including, without limitation, loss of
        business, loss of profit, loss or corruption of data, loss of goodwill or reputation or wasted management time)
        which may be incurred or experienced on account of your using or relying on this website or any information or
        link on it, even if DN Media Group Global has been advised of the possibility of such damages. Any limitations
        or restrictions on liability in these Terms of Use shall only apply to the extent permitted by applicable law.
        In no event shall DN Media Group Global’ or its partners total liability to you for all damages, losses and
        causes of action (whether in contract or tort, including but not limited to, negligence) exceed the amount paid
        by you, if any, for accessing this website.
      </li>
      <li>
        Nothing set forth in this website should be construed as a recommendation to purchase or sell any product or
        instrument or to enter into any transaction, or as a representation that any particular product or instrument is
        suitable or appropriate for you. Many of the products described in this website involve significant risks and
        you should not enter into any transactions unless you fully understand all of these risks and have independently
        determined that the risks are acceptable to you and that the transactions are appropriate for you in light of
        your objectives, experience, financial and operational resources and other relevant circumstances. Any
        discussion of risks contained in this website, however, should not be considered to be a disclosure of the risks
        of particular transactions, or a complete discussion of the risks which are mentioned. You should not construe
        any of the material contained in this website as business, financial, investment, hedging, trading, legal,
        regulatory, tax or accounting advice and you should not make this website the primary basis for any trading
        decisions.
      </li>
      <li>
        You agree, at your own expense, to indemnify, defend and hold harmless DN Media Group Global and its employees,
        representatives, suppliers and agents, against any claim, suit, action or other proceeding against DN Media
        Group Global, its employees, representatives, suppliers and agents, by a third party, to the extent that such
        claim, suit, action or other proceeding is based on or arises in connection with your use of the website, or any
        links on the website. You agree to pay any and all costs, damages and expenses, including, but not limited to,
        reasonable attorneys’ fees and costs awarded against or otherwise incurred by or in connection with or arising
        from any such claim, suit, action or proceeding attributable to any such claim. These Terms of Use shall be
        governed by, and construed in accordance with, the laws of Norway. The user of this website agrees that by using
        this web they submit to the jurisdiction Norway for the enforcement of the terms of this Website Terms of Use
        and any disputes related to same and agrees to service of process by mail.
      </li>
      <li>
        DN Media Group Global and its affiliates and partners do not intend the information provided on this site to be
        distributed to, or used by, any person or entity in any jurisdiction or country where such distribution or use
        would be contrary to law or regulation or which would subject DN Media Group Global or its affiliates to any
        registration requirement within such jurisdiction or country. Neither the information, nor any opinion contained
        in this site constitutes a solicitation or offer by DN Media Group Global or its affiliates to buy or sell any
        securities, futures, options or other financial instruments or provide any investment advice or service.
      </li>
      <li>
        Although the information provided to you on this site is obtained or compiled from sources we believe to be
        reliable, DN Media Group Global cannot and does not guarantee the accuracy, validity, timeliness or completeness
        of any information or data made available to you for any particular purpose. Neither DN Media Group Global, nor
        any of its affiliates, directors, officers or employees, nor any third party vendor will be liable or have any
        responsibility of any kind for any loss or damage that you incur in the event of any failure or interruption of
        this site, or resulting from the act or omission of any other party involved in making this site or the data
        contained therein available to you, or from any other cause relating to your access to, inability to access, or
        use of the site or these materials, whether or not the circumstances giving rise to such cause may have been
        within the control of DN Media Group Global or of any vendor providing software or services support.
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'digital-terms',
};
</script>
